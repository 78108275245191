<template>
  <div class="ui-gray-back">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'GrayBack'
};
</script>

<style lang="scss">
@import '@/styles/spacing';

.ui-gray-back {
  border-radius: 4px;
  background-color: var(--dark-5-color);
  padding: 2 * $spacing-base;
}
</style>
