<template>
  <Flex align-center>
    <PrivilegeIcon :type="privilege" />
    <Typography class="ml-2" size="s">
      {{ PRIVILEGE_NAME[privilege] }}
    </Typography>
  </Flex>
</template>
<script>
import { defineComponent } from 'vue';
import { PRIVILEGE, PRIVILEGE_NAME } from '@/common/constant';
import Flex from '@/components/layout/Flex.vue';
import PrivilegeIcon from '@/components/ui/PrivilegeIcon.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'PrivilegeLabel',
  components: {
    Flex,
    PrivilegeIcon,
    Typography
  },
  props: {
    privilege: String
  },
  setup() {
    return {
      PRIVILEGE,
      PRIVILEGE_NAME
    };
  }
});
</script>
