<template>
  <Flex flex-direction="column" align-center width="280px">
    <AccountIcon class="mb-4" :imageUrl="imageUrl" size="l" />
    <Typography class="mb-4 profile-break-line" size="m">
      {{ name }}
    </Typography>
    <Typography class="mb-4 profile-break-line" size="s" color="grey">
      {{ email }}
    </Typography>
    <PrivilegeLabel :privilege="privilege" class="mb-4"></PrivilegeLabel>
    <Box width="100%" v-if="description">
      <GrayBack>
        <Typography text-align="left" size="s" class="profile-break-line">
          {{ description }}
        </Typography>
      </GrayBack>
    </Box>
  </Flex>
</template>

<script>
import { defineComponent } from 'vue';
import AccountIcon from '@/components/ui/AccountIcon';
import Typography from '@/components/ui/Typography';
import PrivilegeLabel from '@/components/ui/PrivilegeLabel';
import GrayBack from '@/components/ui/GrayBack';
import Flex from '@/components/layout/Flex';
import Box from '@/components/layout/Box';

export default defineComponent({
  name: 'ProfilePanel',
  components: {
    AccountIcon,
    Typography,
    PrivilegeLabel,
    Flex,
    GrayBack,
    Box
  },
  props: {
    imageUrl: String,
    name: String,
    email: String,
    description: String,
    privilege: String
  }
});
</script>

<style scoped>
.profile-break-line {
  word-break: break-all;
}
</style>
