import { ProjectApi } from '@/api';
import { Project } from '@/api/openapi';
import { toast } from '@/components/ui/Toast';

interface UseFavoriteReturnType {
  toggleFavorite: (project: Project) => Promise<void>;
}

export const useFavorite = (): UseFavoriteReturnType => {
  const toggleFavorite = async (project: Project) => {
    if (project.projectId) {
      try {
        const favorite = !project.favorite;
        const result = await ProjectApi.putProjectsProjectIdFavoritesIsFavorite(
          project.projectId,
          { isFavorite: favorite }
        );
        if (result.status < 200 || 300 <= result.status) {
          toast({
            title: 'エラー',
            message: 'お気に入りの設定に失敗しました',
            variant: 'error'
          });
        } else {
          project.favorite = favorite;
        }
      } catch (e) {
        toast({
          title: 'エラー',
          message: 'お気に入りの設定に失敗しました',
          variant: 'error'
        });
      }
    }
  };

  return {
    toggleFavorite
  };
};
